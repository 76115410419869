const defaultState = {
  loadingStatus: '',
};

/**
 * - Strax: Reducer for all our state
 * @param {*} state
 * @param {*} action
 * @returns
 */
export const straxReducer = (state = defaultState, action) => {
  switch (action.type) {
    case 'SET_LOADING_STATUS': {
      return Object.assign({}, state, { loadingStatus: action.status });
    }
    default:
      return state;
  }
};
