const mockedStudies = [
  {
    StudyInstanceUID: 'Dicom_Image_Uncompressed',
    StudyDate: '19600116',
    StudyTime: '082433.00',
    AccessionNumber: undefined,
    referringPhysicianName: undefined,
    PatientName: 'Dicom_Image_Uncompressed',
    PatientID: 'ACRIN-NSCLC-FDG-PET-042',
    PatientBirthdate: undefined,
    patientSex: 'M',
    studyId: undefined,
    numberOfStudyRelatedSeries: '1',
    numberOfStudyRelatedInstances: '934',
    StudyDescription: 'WB PET/CT',
    modalities: 'CT\\PT',
    zipUrl:
      'https://tommy-temp.s3.ap-southeast-2.amazonaws.com/2.16.840.114490.1.3.344370156.15744.1602538963.750.zip',
  },
];

export class StraxStudiesFetcher {
  /**
   * - Strax: Returns fake study for testing only.
   * - You only need to change `StudyInstanceUID` and `zipUrl` if add a new one. All other data is in relevant
   */
  searchForStudies() {
    return mockedStudies;
  }
}
