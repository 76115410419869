/**
 * - Strax: Simple http get method
 * @param {string} url
 * @returns {Promise<Response>}
 */
const get = url => {
  return new Promise((resolve, reject) => {
    fetch(url)
      .then(async res => {
        resolve(res);
      })
      .then(err => {
        reject(err);
      });
  });
};

/**
 * - Strax: Download any file use urland return byte array
 * @param {string} url
 * @returns {Promise<Uint8Array>}
 */
const download = url => {
  return new Promise((resolve, reject) => {
    fetch(url)
      .then(async res => {
        resolve(res.arrayBuffer());
      })
      .then(err => {
        reject(err);
      });
  });
};

export const httpHelper = {
  get,
  download,
};
