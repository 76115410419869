const straxServerNames = { imageZipUrl: 'Image Zip Url' };
// In seconds
const waitTime = 5;
// Wait number of times before error is thrown
// 20 means 20 * 5s = 100 second
const maxWaitTimes = 20;

/**
 * - Strax: Constants we added
 */
export const Constants = {
  straxServerNames,
  waitTime,
  maxWaitTimes,
};
