import dcmjs from 'dcmjs';
import { httpHelper } from './httpHelper';

/**
 * - Strax: Read metadata from dicom image (in memory byte array)
 * - The actual logic already written by someone, search `DicomMetaDictionary.naturalizeDataset` you will see similar logic
 * @param {Uint8Array} byteArray - Dicom image byte array
 * @returns {Metadata}
 */
const getMetaData = byteArray => {
  const dicomData = dcmjs.data.DicomMessage.readFile(byteArray);
  const dataset = dcmjs.data.DicomMetaDictionary.naturalizeDataset(
    dicomData.dict
  );

  // This will cause some error, but seems we don't need it if just for getting data set.
  // Leave this as comment for now
  // dataset._meta = dcmjs.data.DicomMetaDictionary.namifyDataset(
  //   dicomData.meta
  // );
  const {
    StudyInstanceUID,
    StudyDate,
    StudyTime,
    AccessionNumber,
    ReferringPhysicianName,
    PatientName,
    PatientID,
    PatientBirthDate,
    PatientSex,
    StudyID,
    StudyDescription,
    SeriesInstanceUID,
    SeriesDescription,
    SeriesNumber,
  } = dataset;

  const instanceMetadata = {
    ...dataset,
    // > No long needed
    // url: imageId,
    imageRendering: 'dicomfile',
  };

  const seriesMetadata = {
    SeriesInstanceUID: SeriesInstanceUID,
    SeriesDescription: SeriesDescription,
    SeriesNumber: SeriesNumber,
    // > Will be added later
    // instances: [instance],
  };

  const studyMetadata = {
    StudyInstanceUID,
    StudyDate,
    StudyTime,
    AccessionNumber,
    ReferringPhysicianName,
    PatientName,
    PatientID,
    PatientBirthDate,
    PatientSex,
    StudyID,
    StudyDescription,
    // > Will be added later
    // series: [series],
  };

  return { studyMetadata, seriesMetadata, instanceMetadata };
};

/**
 * - Strax: Extract image metadata from image url.
 *   Usually use the first image url in a list of images.
 *   Most data are the same, only `InstanceNumber` will be different
 * - Download the image
 * - Get metadata for instance, series and study
 * @param {string} url
 * @returns {Promise<Metadata>}
 */
const getMetadataFromUrl = async url => {
  const byteArray = await httpHelper.download(url);
  const metaData = getMetaData(byteArray);
  return metaData;
};

export const dicomImageMetadataHelper = {
  getMetadataFromUrl,
};
