/**
 * - Strax: Simple wait promises
 * @param {Number} seconds
 */
export const waitForSeconds = seconds => {
  return new Promise(resolve => {
    setTimeout(() => {
      resolve();
    }, seconds * 1000);
  });
};
