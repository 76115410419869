import metadataProvider from '../../../classes/MetadataProvider';
import { Constants } from '../../../constants';
import { waitForSeconds } from '../../../utils/wait';
import { dicomImageMetadataHelper } from './dicomImageMetadataHelper';
import { httpHelper } from './httpHelper';
import { ResponseAction } from './responseAction';

/**
 * - Strax: Dataloader for all the images
 */
export class StraxMetadataLoader {
  /**
   *
   * @param {string} apiUrl
   * @param {string} studyId
   * @param {string} zipUrl
   */
  constructor(apiUrl, studyId, zipUrl) {
    this.apiUrl = apiUrl;
    this.studyId = studyId;
    this.zipUrl = zipUrl;
  }

  /**
   *
   * @returns {StudyMetadata}
   */
  async execLoad() {
    // - Get image ids from the Strax DICOM Image Service backend api
    const imageUrls = await this.getImageUrls();

    // - Get study and series metadata from first image
    // All image should have that metadata info, and it should have at least one image url available)
    const {
      studyMetadata,
      seriesMetadata,
      instanceMetadata,
    } = await dicomImageMetadataHelper.getMetadataFromUrl(imageUrls[1]);

    // - Build instances from image urls
    const instances = this.buildInstances(instanceMetadata, imageUrls);
    // - Build series from instances
    const series = this.buildSeries(seriesMetadata, instances);
    // - Build study from series
    const study = this.buildStudy(studyMetadata, series);

    // Note:
    // Study  contains Series[]
    // Series contains Instance[]
    return study;
  }

  /**
   * @returns {Promise<string[]>}
   */
  async getImageUrls() {
    const url = `${this.apiUrl}/get?url=${this.zipUrl}&studyId=${this.studyId}`;

    const { maxWaitTimes, waitTime } = Constants;
    let count = 0;

    while (count < maxWaitTimes) {
      // - Trying to get signed urls from the server
      const res = await httpHelper.get(url);
      if (res.status !== 200) throw new Error(res.statusText);

      const body = await res.json();
      // > If server tell us to wait, we will wait for 5 seconds then try again (until max limit reached)
      if (body.action === ResponseAction.Wait || !body.signedUrls) {
        console.log({
          message: body.message,
          timePassed: `${count * waitTime} seconds`,
        });
        count++;
        await waitForSeconds(waitTime);
        continue;
      }
      // > Server tell us we should not wait, but no signed url is returned.
      // > Something is wrong on server
      else if (
        body.action === ResponseAction.None &&
        body.signedUrls.length == 0
      ) {
        throw new Error('Unknown error, signed url is empty');
      }

      // - Sort signed url by name before returns.
      // - Server should technically make sure the url is in order
      const sortedUrls = body.signedUrls.sort(this.sort);
      return sortedUrls;
    }
  }

  /**
   *
   * @param {InstanceMetadata} instanceMetadata
   * @param {string[]} imageUrls
   * @returns {InstanceMetadata[]}
   */
  buildInstances(instanceMetadata, imageUrls) {
    const instances = [];

    for (const url of imageUrls) {
      // `wadouri` is provided by the library
      const imageId = 'wadouri:' + url;

      // - Only add mandatory field to the instance, not entire metadata
      const instance = {
        ImageId: imageId,
        StudyInstanceUID: instanceMetadata.StudyInstanceUID,
        SeriesInstanceUID: instanceMetadata.SeriesInstanceUID,
        SOPInstanceUID: imageId,
        SOPClassUID: instanceMetadata.SOPClassUID,
        Modality: instanceMetadata.Modality,
        type: Constants.straxServerNames.imageZipUrl,

        wadouri: imageId,
        isReconstructable: true,
        imageRendering: 'wadouri',
        thumbnailRendering: 'wadouri',
      };

      // - Add instance details into metadata provider. So it will available for other components
      // ! Important - The `instance` value changed in `addInstanceMetadata`
      // ! Even the return value is not assigned
      metadataProvider.addInstanceMetadata(
        instance.StudyInstanceUID,
        instance.SeriesInstanceUID,
        instance.SOPInstanceUID,
        instance
      );

      instance.metadata = instance;
      instances.push(instance);
    }

    return instances;
  }

  /**
   *
   * @param {SeriesMetadata} seriesMetadata
   * @param {InstanceMetadata[]} instances
   * @returns {SeriesMetadata[]}
   */
  buildSeries(seriesMetadata, instances) {
    const series = {
      SeriesInstanceUID: seriesMetadata.SeriesInstanceUID,
      SeriesDescription: seriesMetadata.SeriesDescription,
      SeriesNumber: seriesMetadata.SeriesNumber,
      instances,
    };

    return [series];
  }

  /**
   *
   * @param {StudyMetadata} studyMetadata
   * @param {SeriesMetadata[]} series
   * @returns {StudyMetadata}
   */
  buildStudy(studyMetadata, series) {
    const study = {
      StudyInstanceUID: studyMetadata.StudyInstanceUID,
      StudyDate: studyMetadata.StudyDate,
      StudyTime: studyMetadata.StudyTime,
      AccessionNumber: studyMetadata.AccessionNumber,
      ReferringPhysicianName: studyMetadata.ReferringPhysicianName,
      PatientName: studyMetadata.PatientName,
      PatientID: studyMetadata.PatientID,
      PatientBirthDate: studyMetadata.PatientBirthDate,
      PatientSex: studyMetadata.PatientSex,
      StudyId: studyMetadata.StudyId,
      StudyDescription: studyMetadata.StudyDescription,
      series,
    };

    return study;
  }

  /**
   *
   * @param {string} a
   * @param {string} b
   * @returns {Number}
   */
  sort(a, b) {
    // Todo: maybe need some update to include other special chars
    const match1 = /[a-zA-Z.-]+(?<index>[0-9]+)\.dcm/gm.exec(a);
    const match2 = /[a-zA-Z.-]+(?<index>[0-9]+)\.dcm/gm.exec(b);
    if (!match1 || !match2) return 0;

    const index1 = Number(match1.groups.index);
    const index2 = Number(match2.groups.index);
    return index1 - index2;
  }
}
